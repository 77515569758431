import React from 'react'
import Layout from '../../components/Layout'
import Header from '../../components/Header'
import Particles from 'react-tsparticles'
import config from '../../assets/lib/particles-recruit.json'

const SoftwareEngineerPageContent = () => (
  <div class="main">
    <section class="section">
      <div class="section__inner section__inner--recruit">
      <ul class="recruit-content">
        <li class="recruit-content__list-item">
          <div class="recruit-content__title">
            <h2 class="recruit-content__title-txt">募集要項</h2>
          </div>
          <div class="recruit-content__table">
            <div class="recruit-content__table-row">
              <div class="recruit-content__header-cell">職種</div>
              <div class="recruit-content__cell">ソフトウェアエンジニア</div>
            </div>
            <div class="recruit-content__table-row">
              <div class="recruit-content__header-cell">勤務場所</div>
              <div class="recruit-content__cell">基本は遠隔（※1）</div>
            </div>
            <div class="recruit-content__table-row">
              <div class="recruit-content__header-cell">勤務形態</div>
              <div class="recruit-content__cell"> 週5日、1日8時間が基本だが、それ以外も可能（週20時間以上）</div>
            </div>
            <div class="recruit-content__table-row">
              <div class="recruit-content__header-cell">契約形態</div>
              <div class="recruit-content__cell">雇用契約（社員）・業務委託契約どちらも可能</div>
            </div>
            <div class="recruit-content__table-row">
              <div class="recruit-content__header-cell">給与</div>
              <div class="recruit-content__cell">25万〜100万/月（※フルタイムの場合の金額）</div>
            </div>
            <div class="recruit-content__table-row">
              <div class="recruit-content__header-cell">必要なスキル等</div>
              <div class="recruit-content__cell">後述</div>
            </div>
            <div class="recruit-content__table-row">
              <div class="recruit-content__header-cell">試用期間</div>
              <div class="recruit-content__cell">3ヶ月</div>
            </div>
            <div class="recruit-content__table-row">
              <div class="recruit-content__header-cell">特記事項</div>
              <div class="recruit-content__cell">保険完備、副業可</div>
            </div>
          </div>
          <p class="recruit-content__annotation">※1:経験の浅い方は、しばらくは週に数日、都内か千葉近辺まで出てきて頂くかもしれません。</p>
        </li>

        <li class="recruit-content__list-item">
          <div class="recruit-content__title">
            <h2 class="recruit-content__title-txt">業務内容</h2>
          </div>
          <h3 class="recruit-content__title-sub">お客様のシステム/webサービスの受託開発</h3>
          <ul class="recruit-content__body">
            <li><a class="recruit-content__link" href="/works/">開発実績</a>もご参照下さい</li>
          </ul>
          <h3 class="recruit-content__title-sub">現在計画中の新サービスの開発</h3>
          <ul class="recruit-content__body">
            <li>技術的には、既に開発済みのサービス（<a class="recruit-content__link" href="/works/#commet">Commet</a>）がベースになるのですが、サービス自体は全く新規のものです</li>
          </ul>
        </li>

        <li class="recruit-content__list-item">
          <div class="recruit-content__title">
            <h2 class="recruit-content__title-txt">必須の条件</h2>
          </div>
          <h3 class="recruit-content__title-sub">ソフトウェア開発、あるいは弊社の事業なり弊社自体に興味・熱意があること</h3>
          <ul class="recruit-content__body">
            <li>仕事で一番必要なのは、興味だったり意欲だと思うので</li>
          </ul>
          <h3 class="recruit-content__title-sub">以下のいずれか</h3>
          <ul class="recruit-content__body">
            <li>ソフトウェア開発実務経験1年以上（言語等は問いません）</li>
            <li>コンピューターサイエンス・情報系の大学卒業</li>
            <li>プログラム・情報系の高専、あるいは専門学校卒業</li>
          </ul>
        </li>

        <li class="recruit-content__list-item">
          <div class="recruit-content__title">
            <h2 class="recruit-content__title-txt">知ってると望ましい技術</h2>
          </div>
          <p class="recruit-content__text">特に重要度の高いものには★をつけてあります。当てはまるものが1つもなくても、ご興味があれば、お問い合わせ下さい。</p>
          <p class="recruit-content__text">また、これらの技術を使う可能性が高いので、<strong>これらを学んでみたいという方</strong>も是非。</p>
          <h3 class="recruit-content__title-sub">プログラム言語</h3>
          <ul class="recruit-content__body">
            <li>★Scala</li>
            <li>★Python</li>
            <li>TypeScript</li>
            <li>PHP</li>
            <li>Swift</li>
          </ul>
          <h3 class="recruit-content__title-sub">Web系ライブラリ、フレームワーク</h3>
          <ul class="recruit-content__body">
            <li>★React / Redux</li>
            <li>Ruby on Rails</li>
            <li>Laravel</li>
            <li>Play Framework</li>
          </ul>

          <h3 class="recruit-content__title-sub">DB・ミドルウェア</h3>
          <ul class="recruit-content__body">
            <li>MySQL</li>
            <li>PostgreSQL</li>
            <li>Elasticsearch</li>
            <li>Spark</li>
          </ul>

          <h3 className="recruit-content__title-sub">インフラ、その他</h3>
          <ul className="recruit-content__body">
            <li>★AWS</li>
            <li>Azure</li>
            <li>Linux</li>
            <li>自然言語処理</li>
          </ul>
          <p class="recruit-content__text">弊社が強い部分は、以下のページにまとめてありますので、良かったらご覧になって下さい。</p>
          <p class="recruit-content__text"><a class="recruit-content__link" href="https://blog.mobalab.net/2019/06/03/our-strengths-ver-2019/">弊社が得意な領域（2019年版） – もばらぶん</a></p>
          <p class="recruit-content__text">また、弊社技術者による技術ブログもありますので、こちらも合わせてご覧下さい。</p>
          <p class="recruit-content__text"><a class="recruit-content__link" href="https://engineering.mobalab.net/">もばらぶエンジニアブログ | 中の人が技術的な話をぼつぼつ書きます</a></p>
        </li>
        <li class="recruit-content__list-item">
          <div class="recruit-content__title">
            <h2 class="recruit-content__title-txt">技術以外で望ましい特性等</h2>
          </div>
          <h3 class="recruit-content__title-sub">英語</h3>
          <ul class="recruit-content__body">
            <li>特に読み（英語の技術文書が多いので）</li>
            <li>書き（オフショア開発等、海外の開発者とやり取りするので）</li>
            <li>会話も出来る方は優遇（同上）</li>
          </ul>
          <h3 class="recruit-content__title-sub">新しい技術・仕事の進め方などへの興味</h3>
          <ul class="recruit-content__body">
            <li>常により良い仕事の進め方を追求しているので、変化に柔軟に対応できる方だと嬉しいです</li>
          </ul>
        </li>
      </ul>

      </div>
    </section>
    <section class="section section--gray">
      <div class="recruit-content__buttons">
        <h3 class="recruit-content__buttons-annotation">「話を聞いてみたい」程度の方も含め、ご興味のある方はまずはご連絡ください。</h3>
        <a href="https://blog.mobalab.net/jobs/job-application-form/" class="btn-blue">採用応募フォーム</a>
        <a href="/recruit/" class="btn-back-to-recruit-list">採用情報一覧に戻る</a>
      </div>
    </section>
  </div>
)

const SoftwareEngineerPage = (props) => (
  <Layout
    title="採用情報・ソフトウェアエンジニア（フルリモート） | 株式会社もばらぶ"
    description="株式会社もばらぶでは、現在フルリモートでのソフトウェアエンジニアを募集しています。ウェブ関連の技術・経験などを活かせるお仕事ですので、ご興味のある方はお気軽にご応募ください。"
    pathName={props.location.pathname}
  >
    <Header />
    <div class="hero hero--job">
      <Particles className="hero__background hero__background--job" params={config}/>
      <div class="hero__title-area">
        <p class="hero__title-ja hero__title-ja--job">採用情報</p>
        <h1 class="hero__title hero__title--job">ソフトウェアエンジニア</h1>
      </div>
    </div>
    <SoftwareEngineerPageContent />
  </Layout>
)
export default SoftwareEngineerPage